
.pricing2-wrapper {
    min-height: 760px
}

.pricing2-wrapper .pricing2>p {
    text-align: center
}

.pricing2-wrapper .pricing2-content-wrapper {
    min-height: 400px
}

.pricing2-wrapper .pricing2-table-name-block {
    text-align: center;
    color: #666;
    width: 100%
}

.pricing2-wrapper .pricing2-table-name {
    font-size: 24px
}

.pricing2-wrapper .pricing2-table-money {
    font-size: 16px;
    margin: 8px 0 16px
}

.pricing2-wrapper .pricing2-table-content {
    text-align: center;
    color: #666
}

.pricing2-wrapper .pricing2-table-content-name {
    color: #666;
    text-align: center
}

.pricing2-wrapper.home-page-wrapper .pricing2-title-wrapper {
    margin-bottom: 64px;
    text-align: center
}

@media screen and (max-width: 767px) {
    .pricing2-wrapper {
        padding-bottom:0
    }

    .pricing2-table {
        margin-bottom: 24px
    }
}

.teams0-wrapper {
    height: 430px
}

.teams0-wrapper .teams0 {
    padding: 64px 24px;
    display: flex;
    align-items: flex-end
}

.teams0-wrapper .teams0 .banner-anim {
    width: 100%;
    height: 100%
}

.teams0-wrapper .teams0 .banner-anim-thumb span {
    background: #e9e9e9;
    box-shadow: none
}

.teams0-wrapper .teams0 .banner-anim-thumb span.active {
    background: #2f54eb
}

.teams0-wrapper .teams0 .queue-anim-leaving {
    position: relative!important
}

.teams0-wrapper .teams0-banner-user-elem {
    height: 100%;
    color: #fff;
    position: relative;
    overflow: hidden
}

.teams0-wrapper .teams0-image {
    width: 120px;
    height: 120px;
    border-radius: 100%;
    overflow: hidden;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 24px
}

.teams0-wrapper .teams0-image img {
    height: 100%
}

.teams0-wrapper .teams0-content {
    font-size: 12px;
    color: #919191;
    text-align: center;
    width: 80%;
    margin: 8px auto
}

.teams0-wrapper .teams0-h1 {
    font-size: 24px;
    text-align: center;
    width: 80%;
    margin: 24px auto 0
}

@media screen and (max-width: 767px) {
    .teams0-wrapper {
        min-height:480px
    }

    .teams0-wrapper .teams0 {
        display: block
    }
}

.teams1-wrapper {
    min-height: 446px;
    overflow: hidden
}

.teams1-wrapper .teams1 {
    overflow: hidden;
    height: 100%;
    padding: 64px 24px
}

.teams1-wrapper .teams1>.title-wrapper {
    margin: 0 auto 48px
}

.teams1-wrapper .teams1 .block-wrapper {
    position: relative;
    height: 100%;
    overflow: hidden;
    padding: 20px 0
}

.teams1-wrapper .teams1 .block-wrapper .block {
    display: inline-block;
    text-align: center;
    margin-bottom: 48px
}

.teams1-wrapper .teams1 .block-wrapper .block.queue-anim-leaving {
    position: relative!important
}

.teams1-wrapper .teams1-image,.teams1-wrapper .teams1-title,.teams1-wrapper .teams1-job,.teams1-wrapper .teams1-content {
    width: 200px;
    margin: auto;
    line-height: 1.5
}

.teams1-wrapper .teams1-image {
    color: #404040
}

.teams1-wrapper .teams1-image img {
    width: 100%
}

.teams1-wrapper .teams1-title {
    font-size: 24px;
    margin: 24px auto 8px
}

.teams1-wrapper .teams1-job {
    margin: 8px auto
}

.teams1-wrapper .teams1-job,.teams1-wrapper .teams1-content {
    font-size: 12px;
    color: #919191
}

@media screen and (max-width: 767px) {
    .teams1-wrapper {
        min-height:1440px
    }
}

.teams2-wrapper {
    min-height: 446px;
    overflow: hidden
}

.teams2-wrapper .teams2 {
    overflow: hidden;
    height: 100%;
    padding: 64px 24px
}

.teams2-wrapper .teams2>.title-wrapper {
    margin: 0 auto 48px
}

.teams2-wrapper .teams2 .block-wrapper {
    position: relative;
    height: 100%;
    overflow: hidden;
    padding: 20px 0;
    min-height: 456px
}

.teams2-wrapper .teams2 .block-wrapper .block {
    margin-bottom: 48px;
    vertical-align: text-top
}

.teams2-wrapper .teams2 .block-wrapper .block.queue-anim-leaving {
    position: relative!important
}

.teams2-wrapper .teams2-image {
    color: #404040;
    width: 100%
}

.teams2-wrapper .teams2-image img {
    width: 100%
}

.teams2-wrapper .teams2-textWrapper {
    padding-left: 16px
}

.teams2-wrapper .teams2-title {
    font-size: 18px;
    margin-bottom: 2px
}

.teams2-wrapper .teams2-job {
    margin-bottom: 4px
}

.teams2-wrapper .teams2-job,.teams2-wrapper .teams2-content {
    font-size: 12px;
    color: #919191
}

@media screen and (max-width: 767px) {
    .teams2-wrapper {
        min-height:1440px
    }

    .teams2-wrapper .teams2.home-page {
        padding-bottom: 0
    }
}

.ant-divider {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: #314659;
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    -webkit-font-feature-settings: 'tnum';
    font-feature-settings: 'tnum';
    background: #f0f0f0
}

.ant-divider,.ant-divider-vertical {
    position: relative;
    top: -.06em;
    display: inline-block;
    width: 1px;
    height: .9em;
    margin: 0 8px;
    vertical-align: middle
}

.ant-divider-horizontal {
    display: block;
    clear: both;
    width: 100%;
    min-width: 100%;
    height: 1px;
    margin: 24px 0
}

.ant-divider-horizontal.ant-divider-with-text-center,.ant-divider-horizontal.ant-divider-with-text-left,.ant-divider-horizontal.ant-divider-with-text-right {
    display: table;
    margin: 16px 0;
    color: rgba(0,0,0,.85);
    font-weight: 500;
    font-size: 16px;
    white-space: nowrap;
    text-align: center;
    background: 0 0
}

.ant-divider-horizontal.ant-divider-with-text-center::before,.ant-divider-horizontal.ant-divider-with-text-left::before,.ant-divider-horizontal.ant-divider-with-text-right::before,.ant-divider-horizontal.ant-divider-with-text-center::after,.ant-divider-horizontal.ant-divider-with-text-left::after,.ant-divider-horizontal.ant-divider-with-text-right::after {
    position: relative;
    top: 50%;
    display: table-cell;
    width: 50%;
    border-top: 1px solid #f0f0f0;
    -webkit-transform: translateY(50%);
    transform: translateY(50%);
    content: ''
}

.ant-divider-horizontal.ant-divider-with-text-left .ant-divider-inner-text,.ant-divider-horizontal.ant-divider-with-text-right .ant-divider-inner-text {
    display: inline-block;
    padding: 0 1em
}

.ant-divider-horizontal.ant-divider-with-text-left::before {
    top: 50%;
    width: 5%
}

.ant-divider-horizontal.ant-divider-with-text-left::after {
    top: 50%;
    width: 95%
}

.ant-divider-horizontal.ant-divider-with-text-right::before {
    top: 50%;
    width: 95%
}

.ant-divider-horizontal.ant-divider-with-text-right::after {
    top: 50%;
    width: 5%
}

.ant-divider-inner-text {
    display: inline-block;
    padding: 0 1em
}

.ant-divider-dashed {
    background: 0 0;
    border-color: #f0f0f0;
    border-style: dashed;
    border-width: 1px 0 0
}

.ant-divider-horizontal.ant-divider-with-text-center.ant-divider-dashed,.ant-divider-horizontal.ant-divider-with-text-left.ant-divider-dashed,.ant-divider-horizontal.ant-divider-with-text-right.ant-divider-dashed {
    border-top: 0
}

.ant-divider-horizontal.ant-divider-with-text-center.ant-divider-dashed::before,.ant-divider-horizontal.ant-divider-with-text-left.ant-divider-dashed::before,.ant-divider-horizontal.ant-divider-with-text-right.ant-divider-dashed::before,.ant-divider-horizontal.ant-divider-with-text-center.ant-divider-dashed::after,.ant-divider-horizontal.ant-divider-with-text-left.ant-divider-dashed::after,.ant-divider-horizontal.ant-divider-with-text-right.ant-divider-dashed::after {
    border-style: dashed none none
}

.ant-divider-vertical.ant-divider-dashed {
    border-width: 0 0 0 1px
}

.teams3-wrapper {
    min-height: 446px;
    overflow: hidden
}

.teams3-wrapper .teams3 {
    overflow: hidden;
    height: 100%;
    padding: 64px 24px
}

.teams3-wrapper .teams3>.title-wrapper {
    margin: 0 auto 48px
}

.teams3-wrapper .teams3 .block-top-wrapper {
    position: relative;
    height: 100%;
    overflow: hidden;
    padding: 20px 0
}

.teams3-wrapper .teams3 .block-top-wrapper .block-top {
    display: inline-block;
    text-align: center;
    margin-bottom: 48px
}

.teams3-wrapper .teams3 .block-top-wrapper .block-top.queue-anim-leaving {
    position: relative!important
}

.teams3-wrapper .teams3-top-image,.teams3-wrapper .teams3-top-title,.teams3-wrapper .teams3-top-job,.teams3-wrapper .teams3-top-content {
    width: 200px;
    margin: auto;
    line-height: 1.5
}

.teams3-wrapper .teams3-top-image {
    color: #404040
}

.teams3-wrapper .teams3-top-image img {
    width: 100%
}

.teams3-wrapper .teams3-top-title {
    font-size: 24px;
    margin: 24px auto 8px
}

.teams3-wrapper .teams3-top-job {
    margin: 8px auto
}

.teams3-wrapper .teams3-top-job,.teams3-wrapper .teams3-top-content {
    font-size: 12px;
    color: #919191
}

.teams3-wrapper .teams3 .block-wrapper {
    position: relative;
    height: 100%;
    overflow: hidden;
    padding: 20px 0;
    min-height: 456px;
    margin-top: 48px
}

.teams3-wrapper .teams3 .block-wrapper .block {
    margin-bottom: 48px;
    vertical-align: text-top
}

.teams3-wrapper .teams3 .block-wrapper .block.queue-anim-leaving {
    position: relative!important
}

.teams3-wrapper .teams3-image {
    color: #404040;
    width: 100%
}

.teams3-wrapper .teams3-image img {
    width: 100%
}

.teams3-wrapper .teams3-textWrapper {
    padding-left: 16px
}

.teams3-wrapper .teams3-title {
    font-size: 18px;
    margin-bottom: 2px
}

.teams3-wrapper .teams3-job {
    margin-bottom: 4px
}

.teams3-wrapper .teams3-job,.teams3-wrapper .teams3-content {
    font-size: 12px;
    color: #919191
}

@media screen and (max-width: 767px) {
    .teams3-wrapper {
        min-height:1440px
    }

    .teams3-wrapper .teams3.home-page {
        padding-bottom: 0
    }
}

.ant-carousel {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: #314659;
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    -webkit-font-feature-settings: 'tnum';
    font-feature-settings: 'tnum'
}

.ant-carousel .slick-slider {
    position: relative;
    display: block;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-touch-callout: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent
}

.ant-carousel .slick-list {
    position: relative;
    display: block;
    margin: 0;
    padding: 0;
    overflow: hidden
}

.ant-carousel .slick-list:focus {
    outline: none
}

.ant-carousel .slick-list.dragging {
    cursor: pointer
}

.ant-carousel .slick-list .slick-slide {
    pointer-events: none
}

.ant-carousel .slick-list .slick-slide.slick-active {
    pointer-events: auto
}

.ant-carousel .slick-slider .slick-track,.ant-carousel .slick-slider .slick-list {
    -webkit-transform: translate3d(0,0,0);
    transform: translate3d(0,0,0)
}

.ant-carousel .slick-track {
    position: relative;
    top: 0;
    left: 0;
    display: block
}

.ant-carousel .slick-track::before,.ant-carousel .slick-track::after {
    display: table;
    content: ''
}

.ant-carousel .slick-track::after {
    clear: both
}

.slick-loading .ant-carousel .slick-track {
    visibility: hidden
}

.ant-carousel .slick-slide {
    display: none;
    float: left;
    height: 100%;
    min-height: 1px
}

.ant-carousel .slick-slide img {
    display: block
}

.ant-carousel .slick-slide.slick-loading img {
    display: none
}

.ant-carousel .slick-slide.dragging img {
    pointer-events: none
}

.ant-carousel .slick-initialized .slick-slide {
    display: block
}

.ant-carousel .slick-loading .slick-slide {
    visibility: hidden
}

.ant-carousel .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent
}

.ant-carousel .slick-arrow.slick-hidden {
    display: none
}

.ant-carousel .slick-prev,.ant-carousel .slick-next {
    position: absolute;
    top: 50%;
    display: block;
    width: 20px;
    height: 20px;
    margin-top: -10px;
    padding: 0;
    color: transparent;
    font-size: 0;
    line-height: 0;
    background: 0 0;
    border: 0;
    outline: none;
    cursor: pointer
}

.ant-carousel .slick-prev:hover,.ant-carousel .slick-next:hover,.ant-carousel .slick-prev:focus,.ant-carousel .slick-next:focus {
    color: transparent;
    background: 0 0;
    outline: none
}

.ant-carousel .slick-prev:hover::before,.ant-carousel .slick-next:hover::before,.ant-carousel .slick-prev:focus::before,.ant-carousel .slick-next:focus::before {
    opacity: 1
}

.ant-carousel .slick-prev.slick-disabled::before,.ant-carousel .slick-next.slick-disabled::before {
    opacity: .25
}

.ant-carousel .slick-prev {
    left: -25px
}

.ant-carousel .slick-prev::before {
    content: '←'
}

.ant-carousel .slick-next {
    right: -25px
}

.ant-carousel .slick-next::before {
    content: '→'
}

.ant-carousel .slick-dots {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 15;
    display: -webkit-box!important;
    display: -ms-flexbox!important;
    display: flex!important;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-right: 15%;
    margin-left: 15%;
    padding-left: 0;
    list-style: none
}

.ant-carousel .slick-dots-bottom {
    bottom: 12px
}

.ant-carousel .slick-dots-top {
    top: 12px
}

.ant-carousel .slick-dots li {
    position: relative;
    display: inline-block;
    -webkit-box-flex: 0;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    width: 16px;
    height: 3px;
    margin: 0 2px;
    margin-right: 3px;
    margin-left: 3px;
    padding: 0;
    text-align: center;
    text-indent: -999px;
    vertical-align: top;
    -webkit-transition: all .5s;
    transition: all .5s
}

.ant-carousel .slick-dots li button {
    display: block;
    width: 100%;
    height: 3px;
    padding: 0;
    color: transparent;
    font-size: 0;
    background: #fff;
    border: 0;
    border-radius: 1px;
    outline: none;
    cursor: pointer;
    opacity: .3;
    -webkit-transition: all .5s;
    transition: all .5s
}

.ant-carousel .slick-dots li button:hover,.ant-carousel .slick-dots li button:focus {
    opacity: .75
}

.ant-carousel .slick-dots li.slick-active {
    width: 24px
}

.ant-carousel .slick-dots li.slick-active button {
    background: #fff;
    opacity: 1
}

.ant-carousel .slick-dots li.slick-active:hover,.ant-carousel .slick-dots li.slick-active:focus {
    opacity: 1
}

.ant-carousel-vertical .slick-dots {
    top: 50%;
    bottom: auto;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 3px;
    height: auto;
    margin: 0;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%)
}

.ant-carousel-vertical .slick-dots-left {
    right: auto;
    left: 12px
}

.ant-carousel-vertical .slick-dots-right {
    right: 12px;
    left: auto
}

.ant-carousel-vertical .slick-dots li {
    width: 3px;
    height: 16px;
    margin: 4px 2px;
    vertical-align: baseline
}

.ant-carousel-vertical .slick-dots li button {
    width: 3px;
    height: 16px
}

.ant-carousel-vertical .slick-dots li.slick-active {
    width: 3px;
    height: 24px
}

.ant-carousel-vertical .slick-dots li.slick-active button {
    width: 3px;
    height: 24px
}

.ant-carousel-rtl {
    direction: rtl
}

.ant-carousel-rtl .ant-carousel .slick-track {
    right: 0;
    left: auto
}

.ant-carousel-rtl .ant-carousel .slick-prev {
    right: -25px;
    left: auto
}

.ant-carousel-rtl .ant-carousel .slick-prev::before {
    content: '→'
}

.ant-carousel-rtl .ant-carousel .slick-next {
    right: auto;
    left: -25px
}

.ant-carousel-rtl .ant-carousel .slick-next::before {
    content: '←'
}

.ant-carousel-rtl.ant-carousel .slick-dots {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse
}

.ant-carousel-rtl.ant-carousel-vertical .slick-dots {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column
}

.feature6-wrapper {
    min-height: 360px;
    margin: 0 auto;
    overflow: hidden
}

.feature6-wrapper.home-page-wrapper .home-page {
    padding: 64px 24px
}

.feature6-title {
    display: inline-block
}

.feature6-title-wrapper {
    text-align: center;
    margin-bottom: 48px
}

.feature6-title-text {
    display: inline-block;
    margin: 0 72px;
    font-size: 24px;
    color: #314659;
    transition: color .45s cubic-bezier(.645,.045,.355,1);
    cursor: pointer
}

.feature6-title-text.active {
    color: #2f54eb
}

.feature6-title-bar {
    height: 6px;
    background: #2f54eb;
    width: 20%;
    margin: auto;
    display: block
}

.feature6-title-bar-wrapper {
    position: relative;
    margin-top: 8px;
    transition: left .45s cubic-bezier(.645,.045,.355,1)
}

.feature6-number,.feature6-text {
    text-align: center
}

.feature6-number {
    font-size: 48px;
    color: #2f54eb;
    font-weight: 600
}

.feature6-number-wrapper {
    text-align: center
}

.feature6-text {
    font-size: 20px
}

.feature6-unit {
    font-size: 16px;
    color: #2f54eb
}

.feature6 .ant-carousel .slick-dots-bottom {
    bottom: -24px
}

.feature6 .ant-carousel .slick-dots li button {
    background: rgba(47,84,235,.3)
}

.feature6 .ant-carousel .slick-dots li.slick-active button {
    background: #2f54eb
}

@media screen and (max-width: 767px) {
    .feature6-wrapper {
        padding-bottom:0;
        min-height: 580px
    }

    .feature6-wrapper.home-page-wrapper .home-page {
        padding: 56px 24px 64px
    }

    .feature6-title-text {
        margin: 0 14px
    }

    .feature6-title-bar {
        width: 40%
    }

    .feature6-number-wrapper {
        margin-bottom: 16px
    }
}

.feature7-wrapper {
    min-height: 564px;
    margin: 0 auto;
    overflow: hidden;
    background-color: #f7f9fc
}

.feature7-wrapper.home-page-wrapper .home-page {
    padding: 64px 24px
}

.feature7-title-wrapper {
    text-align: center;
    margin-bottom: 40px
}

.feature7-title-h1 {
    font-size: 32px;
    color: #314659
}

.feature7-title-content {
    margin-top: 16px
}

.feature7-block {
    margin-top: 28px
}

.feature7-block-group {
    display: block;
    padding: 28px 24px;
    box-shadow: 0 2px 16px rgba(0,0,0,.08);
    background-image: url(https://gw.alipayobjects.com/mdn/rms_ae7ad9/afts/img/A*fMOFSpRXMxsAAAAAAAAAAABkARQnAQ);
    background-repeat: no-repeat;
    background-position: 100% 100%;
    transition: box-shadow .45s cubic-bezier(.645,.045,.355,1),transform .45s cubic-bezier(.645,.045,.355,1)
}

.feature7-block-group:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 16px rgba(0,0,0,.12)
}

.feature7-block-image {
    float: left;
    width: 24px
}

.feature7-block-title {
    font-size: 14px;
    float: left;
    margin-left: 8px;
    margin-bottom: 16px;
    color: #314659
}

.feature7-block-content {
    clear: both;
    color: rgba(49,70,89,.75)
}

@media screen and (max-width: 767px) {
    .feature7-wrapper {
        min-height:1540px
    }

    .feature7-wrapper.home-page-wrapper .home-page {
        padding: 56px 24px
    }
}

.feature8-wrapper {
    min-height: 680px;
    margin: 0 auto;
    overflow: hidden;
    background-color: rgba(253,253,253,.3);
    background-image: linear-gradient(360deg,rgba(193,218,255,0.3) 0%,rgba(253,253,253,0.3) 80%)
}

.feature8-wrapper.home-page-wrapper .home-page {
    padding: 64px 24px
}

.feature8-title-wrapper {
    text-align: center;
    margin-bottom: 40px
}

.feature8-title-h1 {
    font-size: 32px;
    color: #314659
}

.feature8-title-content {
    margin-top: 16px
}

.feature8-carousel {
    width: calc(100% + 72px);
    margin-left: -36px
}

.feature8-carousel-title {
    font-size: 20px;
    text-align: center
}

.feature8-carousel-title-block {
    display: inline-block;
    color: rgba(49,70,89,.45);
    transition: color .45s;
    padding: 0 40px;
    position: relative;
    cursor: pointer
}

.feature8-carousel-title-block.active {
    color: #314659;
    cursor: auto
}

.feature8-carousel-title-block::before {
    display: block;
    content: '';
    width: 1px;
    height: 70%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background: #d8d8d8
}

.feature8-carousel-title-block:last-child::before {
    display: none
}

.feature8-block {
    margin-top: 48px
}

.feature8-block-wrapper {
    margin-top: 32px
}

.feature8-block-row {
    padding: 0 36px
}

.feature8-block-col {
    text-align: center;
    margin: 36px 0;
    position: relative
}

.feature8-block-col:last-child .feature8-block-arrow {
    display: none
}

.feature8-block-child {
    padding: 36px 24px 24px;
    border-radius: 6px;
    height: 100%;
    transition: box-shadow .45s cubic-bezier(.645,.045,.355,1),background .45s cubic-bezier(.645,.045,.355,1)
}

.feature8-block-child:hover {
    background-color: #f6f9ff;
    box-shadow: 0 12px 36px rgba(100,100,136,.15)
}

.feature8-block-child:hover .feature8-block-content {
    opacity: 1
}

.feature8-block-image {
    display: inline-block
}

.feature8-block-title {
    font-size: 16px;
    font-weight: 400;
    color: #314659;
    margin-top: 16px
}

.feature8-block-content {
    opacity: 1;
    font-size: 14px;
    line-height: 22px;
    color: rgba(49,70,89,.65);
    margin-top: 36px;
    text-align: left;
    transition: opacity .45s cubic-bezier(.645,.045,.355,1)
}

.feature8-block-arrow {
    position: absolute;
    right: 0;
    width: 16px;
    top: 80px
}

.feature8-button {
    text-align: center
}

.feature8-button-wrapper {
    margin-top: 16px
}

.feature8-button .ant-btn {
    padding: 8px 40px;
    border-radius: 20px;
    line-height: 1.5;
    height: 40px;
    border: none;
    color: #fff;
    background-color: #3c89f6;
    background-image: linear-gradient(135deg,#82b5ff 0%,#3c89f6 100%);
    box-shadow: 0 9px 22px rgba(95,155,241,.35);
    transition: color .45s cubic-bezier(.645,.045,.355,1),box-shadow .45s cubic-bezier(.645,.045,.355,1),transform .45s cubic-bezier(.645,.045,.355,1);
    will-change: transform,box-shadow
}

.feature8-button .ant-btn:hover {
    transform: translateY(-5px);
    box-shadow: 0 14px 26px rgba(95,155,241,.4)
}

@media screen and (max-width: 767px) {
    .feature8-wrapper {
        padding-bottom:0;
        min-height: 1540px
    }

    .feature8-wrapper.home-page-wrapper .home-page {
        padding: 56px 24px 64px
    }

    .feature8-carousel-title {
        font-size: 16px
    }

    .feature8-carousel-title-block {
        padding: 0 8px
    }

    .feature8-block-child {
        width: 80%;
        margin: auto;
        background-color: #f6f9ff;
        box-shadow: 0 12px 36px rgba(100,100,136,.15)
    }

    .feature8-block-content {
        text-align: center
    }

    .feature8-block-arrow {
        bottom: -40px;
        top: auto;
        left: 0;
        right: 0;
        margin: auto;
        transform: rotate(90deg)
    }
}

.footer0-wrapper {
    background-color: #001529;
    height: 80px;
    overflow: hidden
}

.footer0-wrapper .footer0 {
    height: 100%;
    padding: 0 24px;
    line-height: 80px;
    text-align: center;
    color: #999;
    position: relative
}

@media screen and (max-width: 767px) {
    .footer0-wrapper .footer0 {
        font-size:12px
    }

    .footer0-wrapper .footer0.home-page {
        padding: 0
    }

    .footer0-wrapper .footer0>div {
        width: 90%;
        margin: auto
    }
}

.footer1-wrapper {
    background: #001529;
    overflow: hidden;
    position: relative;
    min-height: 360px;
    color: #999
}

.footer1-wrapper .footer1 .home-page {
    padding: 64px 24px 80px
}

.footer1-wrapper .block {
    padding: 0 32px
}

.footer1-wrapper .block .logo {
    max-width: 180px
}

.footer1-wrapper .block .slogan {
    font-size: 12px;
    margin-top: -20px
}

.footer1-wrapper .block>h2 {
    margin-bottom: 24px;
    color: #ccc
}

.footer1-wrapper .block a {
    color: #999;
    margin-bottom: 12px;
    float: left;
    clear: both
}

.footer1-wrapper .block a:hover {
    color: #2f54eb
}

.footer1-wrapper .copyright-wrapper {
    width: 100%;
    border-top: 1px solid rgba(233,233,233,.1)
}

.footer1-wrapper .copyright-wrapper .home-page {
    padding: 0 24px;
    overflow: hidden
}

.footer1-wrapper .copyright-wrapper .copyright {
    height: 80px;
    text-align: center;
    line-height: 80px
}

@media screen and (max-width: 767px) {
    .footer1 {
        min-height:550px
    }

    .footer1-wrapper .footer1 .home-page {
        padding: 64px 24px 32px
    }

    .footer1 .logo {
        margin: 0 auto 24px
    }

    .footer1 .block {
        text-align: center;
        margin-bottom: 32px;
        padding: 0
    }

    .footer1>ul {
        width: 90%;
        margin: 20px auto 0;
        padding: 10px 0
    }

    .footer1>ul>li {
        width: 100%
    }

    .footer1>ul>li h2 {
        margin-bottom: 10px
    }

    .footer1>ul>li li {
        display: inline-block;
        margin-right: 10px
    }

    .footer1 .copyright-wrapper .home-page {
        padding: 0
    }

    .footer1 .copyright-wrapper .home-page .copyright {
        font-size: 12px
    }

    .footer1 .copyright span {
        width: 90%
    }
}

.footer2-wrapper {
    background-color: #0d1a26;
    height: 80px;
    overflow: hidden
}

.footer2-wrapper .footer2 {
    height: 100%;
    padding: 0 24px;
    line-height: 80px;
    text-align: center;
    color: #999;
    position: relative
}

.footer2-wrapper .copyright {
    float: right
}

.footer2-wrapper .copyright>* {
    display: inline-block
}

.footer2-wrapper .copyright-logo {
    width: 16px;
    margin-right: 8px
}

.footer2-wrapper .copyright-logo img {
    width: 100%
}

.footer2-wrapper .copyright-line {
    padding: 0 12px;
    margin: 0 12px
}

.footer2-wrapper .links {
    float: left;
    display: flex;
    align-items: center;
    height: 100%
}

.footer2-wrapper .links a {
    height: 21px;
    display: inline-block;
    margin-right: 32px
}

.footer2-wrapper .links a img {
    display: block
}

@media screen and (max-width: 767px) {
    .footer2-wrapper .footer2 {
        font-size:12px
    }

    .footer2-wrapper .footer2.home-page {
        padding: 0
    }

    .footer2-wrapper .footer2>div {
        width: 90%;
        margin: auto
    }
}

.drawer {
    position: fixed;
    z-index: 9999;
    transition: width 0s ease .3s,height 0s ease .3s,transform .3s cubic-bezier(.78,.14,.15,.86)
}

.drawer>* {
    transition: transform .3s cubic-bezier(.78,.14,.15,.86),opacity .3s cubic-bezier(.78,.14,.15,.86),box-shadow .3s cubic-bezier(.78,.14,.15,.86)
}

.drawer.drawer-open {
    transition: transform .3s cubic-bezier(.78,.14,.15,.86)
}

.drawer .drawer-mask {
    background: #000;
    opacity: 0;
    width: 100%;
    height: 0;
    position: absolute;
    top: 0;
    left: 0;
    transition: opacity .3s cubic-bezier(.78,.14,.15,.86),height 0s ease .3s
}

.drawer-content-wrapper {
    position: absolute;
    background: #fff
}

.drawer-content {
    overflow: auto;
    z-index: 1;
    position: relative
}

.drawer-handle {
    position: absolute;
    top: 72px;
    width: 41px;
    height: 40px;
    cursor: pointer;
    z-index: 0;
    text-align: center;
    line-height: 40px;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff
}

.drawer-handle-icon {
    width: 14px;
    height: 2px;
    background: #333;
    position: relative;
    transition: background .3s cubic-bezier(.78,.14,.15,.86)
}

.drawer-handle-icon:before,.drawer-handle-icon:after {
    content: '';
    display: block;
    position: absolute;
    background: #333;
    width: 100%;
    height: 2px;
    transition: transform .3s cubic-bezier(.78,.14,.15,.86)
}

.drawer-handle-icon:before {
    top: -5px
}

.drawer-handle-icon:after {
    top: 5px
}

.drawer-left,.drawer-right {
    width: 0%;
    height: 100%
}

.drawer-left .drawer-content-wrapper,.drawer-right .drawer-content-wrapper,.drawer-left .drawer-content,.drawer-right .drawer-content {
    height: 100%
}

.drawer-left.drawer-open,.drawer-right.drawer-open {
    width: 100%
}

.drawer-left.drawer-open.no-mask,.drawer-right.drawer-open.no-mask {
    width: 0%
}

.drawer-left {
    top: 0;
    left: 0
}

.drawer-left .drawer-handle {
    right: -40px;
    box-shadow: 2px 0 8px rgba(0,0,0,.15);
    border-radius: 0 4px 4px 0
}

.drawer-left.drawer-open .drawer-content-wrapper {
    box-shadow: 2px 0 8px rgba(0,0,0,.15)
}

.drawer-right {
    top: 0;
    right: 0
}

.drawer-right .drawer-content-wrapper {
    right: 0
}

.drawer-right .drawer-handle {
    left: -40px;
    box-shadow: -2px 0 8px rgba(0,0,0,.15);
    border-radius: 4px 0 0 4px
}

.drawer-right.drawer-open .drawer-content-wrapper {
    box-shadow: -2px 0 8px rgba(0,0,0,.15)
}

.drawer-right.drawer-open.no-mask {
    right: 1px;
    transform: translateX(1px)
}

.drawer-top,.drawer-bottom {
    width: 100%;
    height: 0%
}

.drawer-top .drawer-content-wrapper,.drawer-bottom .drawer-content-wrapper,.drawer-top .drawer-content,.drawer-bottom .drawer-content {
    width: 100%
}

.drawer-top .drawer-content,.drawer-bottom .drawer-content {
    height: 100%
}

.drawer-top.drawer-open,.drawer-bottom.drawer-open {
    height: 100%
}

.drawer-top.drawer-open.no-mask,.drawer-bottom.drawer-open.no-mask {
    height: 0%
}

.drawer-top .drawer-handle,.drawer-bottom .drawer-handle {
    left: 50%;
    margin-left: -20px
}

.drawer-top {
    top: 0;
    left: 0
}

.drawer-top .drawer-handle {
    top: auto;
    bottom: -40px;
    box-shadow: 0 2px 8px rgba(0,0,0,.15);
    border-radius: 0 0 4px 4px
}

.drawer-top.drawer-open .drawer-content-wrapper {
    box-shadow: 0 2px 8px rgba(0,0,0,.15)
}

.drawer-bottom {
    bottom: 0;
    left: 0
}

.drawer-bottom .drawer-content-wrapper {
    bottom: 0
}

.drawer-bottom .drawer-handle {
    top: -40px;
    box-shadow: 0 -2px 8px rgba(0,0,0,.15);
    border-radius: 4px 4px 0 0
}

.drawer-bottom.drawer-open .drawer-content-wrapper {
    box-shadow: 0 -2px 8px rgba(0,0,0,.15)
}

.drawer-bottom.drawer-open.no-mask {
    bottom: 1px;
    transform: translateY(1px)
}

.drawer.drawer-open .drawer-mask {
    opacity: .3;
    height: 100%;
    transition: opacity .3s cubic-bezier(.78,.14,.15,.86)
}

.drawer.drawer-open .drawer-handle-icon {
    background: 0 0
}

.drawer.drawer-open .drawer-handle-icon:before {
    transform: translateY(5px) rotate(45deg)
}

.drawer.drawer-open .drawer-handle-icon:after {
    transform: translateY(-5px) rotate(-45deg)
}

.github-btn {
    font: bold 11px/14px 'Helvetica Neue',Helvetica,Arial,sans-serif;
    height: 20px;
    overflow: hidden
}

.gh-btn,.gh-count,.gh-ico {
    float: left
}

.gh-btn,.gh-count {
    padding: 2px 5px 2px 4px;
    color: #333;
    text-decoration: none;
    white-space: nowrap;
    cursor: pointer;
    border-radius: 3px
}

.gh-btn {
    background-color: #eee;
    background-image: linear-gradient(to bottom,#fcfcfc 0,#eee 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fcfcfc',endColorstr='#eeeeee',GradientType=0);
    background-repeat: no-repeat;
    border: 1px solid #d5d5d5
}

.gh-btn:hover,.gh-btn:focus {
    text-decoration: none;
    background-color: #ddd;
    background-image: linear-gradient(to bottom,#eee 0,#ddd 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#eeeeee',endColorstr='#dddddd',GradientType=0);
    border-color: #ccc
}

.gh-btn:active {
    background-image: none;
    background-color: #dcdcdc;
    border-color: #b5b5b5;
    box-shadow: inset 0 2px 4px rgba(0,0,0,.15)
}

.gh-ico {
    width: 14px;
    height: 14px;
    margin-right: 4px;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIGlkPSJMYXllcl8xIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjQwcHgiIGhlaWdodD0iNDBweCIgdmlld0JveD0iMTIgMTIgNDAgNDAiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMTIgMTIgNDAgNDAiIHhtbDpzcGFjZT0icHJlc2VydmUiPjxwYXRoIGZpbGw9IiMzMzMzMzMiIGQ9Ik0zMiAxMy40Yy0xMC41IDAtMTkgOC41LTE5IDE5YzAgOC40IDUuNSAxNS41IDEzIDE4YzEgMC4yIDEuMy0wLjQgMS4zLTAuOWMwLTAuNSAwLTEuNyAwLTMuMiBjLTUuMyAxLjEtNi40LTIuNi02LjQtMi42QzIwIDQxLjYgMTguOCA0MSAxOC44IDQxYy0xLjctMS4yIDAuMS0xLjEgMC4xLTEuMWMxLjkgMC4xIDIuOSAyIDIuOSAyYzEuNyAyLjkgNC41IDIuMSA1LjUgMS42IGMwLjItMS4yIDAuNy0yLjEgMS4yLTIuNmMtNC4yLTAuNS04LjctMi4xLTguNy05LjRjMC0yLjEgMC43LTMuNyAyLTUuMWMtMC4yLTAuNS0wLjgtMi40IDAuMi01YzAgMCAxLjYtMC41IDUuMiAyIGMxLjUtMC40IDMuMS0wLjcgNC44LTAuN2MxLjYgMCAzLjMgMC4yIDQuNyAwLjdjMy42LTIuNCA1LjItMiA1LjItMmMxIDIuNiAwLjQgNC42IDAuMiA1YzEuMiAxLjMgMiAzIDIgNS4xYzAgNy4zLTQuNSA4LjktOC43IDkuNCBjMC43IDAuNiAxLjMgMS43IDEuMyAzLjVjMCAyLjYgMCA0LjYgMCA1LjJjMCAwLjUgMC40IDEuMSAxLjMgMC45YzcuNS0yLjYgMTMtOS43IDEzLTE4LjFDNTEgMjEuOSA0Mi41IDEzLjQgMzIgMTMuNHoiLz48L3N2Zz4=);
    background-size: 100% 100%;
    background-repeat: no-repeat
}

.gh-count {
    position: relative;
    display: none;
    margin-left: 4px;
    background-color: #fafafa;
    border: 1px solid #d4d4d4
}

.gh-count:hover,.gh-count:focus {
    color: #4183c4
}

.gh-count:before,.gh-count:after {
    content: '';
    position: absolute;
    display: inline-block;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid
}

.gh-count:before {
    top: 50%;
    left: -3px;
    margin-top: -4px;
    border-width: 4px 4px 4px 0;
    border-right-color: #fafafa
}

.gh-count:after {
    top: 50%;
    left: -4px;
    z-index: -1;
    margin-top: -5px;
    border-width: 5px 5px 5px 0;
    border-right-color: #d4d4d4
}

.github-btn-large {
    height: 30px
}

.github-btn-large .gh-btn,.github-btn-large .gh-count {
    padding: 3px 10px 3px 8px;
    font-size: 16px;
    line-height: 22px;
    border-radius: 4px
}

.github-btn-large .gh-ico {
    width: 20px;
    height: 20px
}

.github-btn-large .gh-count {
    margin-left: 6px
}

.github-btn-large .gh-count:before {
    left: -5px;
    margin-top: -6px;
    border-width: 6px 6px 6px 0
}

.github-btn-large .gh-count:after {
    left: -6px;
    margin-top: -7px;
    border-width: 7px 7px 7px 0
}

html,body {
    height: 100%
}

body,div,dl,dt,dd,ul,ol,li,h1,h2,h3,h4,h5,h6 {
    margin: 0;
    padding: 0
}

body {
    font-family: -apple-system,BlinkMacSystemFont,segoe ui,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji;
    overflow-x: hidden;
    -webkit-font-smoothing: antialiased
}

a:focus {
    text-decoration: none
}

#nprogress .bar {
    background: #fff
}

#nprogress .spinner-icon {
    border-top-color: #fff;
    border-left-color: #fff
}



.page-wrapper {
    /* background: #fff */
}

.page-wrapper .page {
    margin: auto;
    max-width: 100%;
    transition: padding .45s cubic-bezier(.215,.61,.355,1),max-width .45s cubic-bezier(.215,.61,.355,1);
    padding: 0 20px
}

.drawer-content {
    padding: 40px 0
}

.landing-move-enter,.landing-move-appear {
    -webkit-animation-duration: .45s;
    animation-duration: .45s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-play-state: paused;
    animation-play-state: paused
}

.landing-move-leave {
    -webkit-animation-duration: .45s;
    animation-duration: .45s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-play-state: paused;
    animation-play-state: paused
}

.landing-move-enter.landing-move-enter-active,.landing-move-appear.landing-move-appear-active {
    -webkit-animation-name: landingMoveIn;
    animation-name: landingMoveIn;
    -webkit-animation-play-state: running;
    animation-play-state: running
}

.landing-move-leave.landing-move-leave-active {
    -webkit-animation-name: landingMoveOut;
    animation-name: landingMoveOut;
    -webkit-animation-play-state: running;
    animation-play-state: running;
    pointer-events: none
}

.landing-move-enter,.landing-move-appear {
    opacity: 0;
    -webkit-animation-timing-function: cubic-bezier(.215,.61,.355,1);
    animation-timing-function: cubic-bezier(.215,.61,.355,1);
    will-change: transform
}

.landing-move-leave {
    -webkit-animation-timing-function: cubic-bezier(.215,.61,.355,1);
    animation-timing-function: cubic-bezier(.215,.61,.355,1);
    position: absolute!important;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1;
    will-change: transform
}

@-webkit-keyframes landingMoveIn {
    0% {
        transform: translateY(30px);
        opacity: 0
    }

    100% {
        transform: translateY(0%);
        opacity: 1
    }
}

@keyframes landingMoveIn {
    0% {
        transform: translateY(30px);
        opacity: 0
    }

    100% {
        transform: translateY(0%);
        opacity: 1
    }
}

@-webkit-keyframes landingMoveOut {
    0% {
        transform: translateY(0);
        opacity: 1
    }

    100% {
        transform: translateY(-30px);
        opacity: 0
    }
}

@keyframes landingMoveOut {
    0% {
        transform: translateY(0);
        opacity: 1
    }

    100% {
        transform: translateY(-30px);
        opacity: 0
    }
}


footer {
    clear: both;
    font-size: 14px;
    background-color: #000;
    position: relative;
    z-index: 100;
    color: rgba(255,255,255,.65);
    box-shadow: 0 1000px 0 1000px #fff
}

footer .ant-row {
    text-align: center
}

footer .ant-row .footer-center {
    display: inline-block;
    text-align: left
}

footer .ant-row .footer-center>h2 {
    font-size: 16px;
    margin: 0 auto 24px;
    font-weight: 500;
    position: relative
}

footer .ant-row .footer-center>h2>.title-icon {
    width: 27px;
    margin-right: 16px
}

footer .ant-row .footer-center>h2>.anticon {
    font-size: 16px;
    position: absolute;
    left: -22px;
    top: 3px;
    color: #aaa
}

footer .ant-row .footer-center>div {
    margin: 12px 0
}

footer .footer-wrap {
    position: relative;
    padding: 86px 144px 93px;
    border-bottom: 1px solid rgba(255,255,255,.25)
}

footer .bottom-bar {
    text-align: center;
    padding: 16px 0;
    margin: 0;
    line-height: 32px;
    overflow: hidden;
    font-family: Avenir,-apple-system,BlinkMacSystemFont,segoe ui,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji,sans-serif;
    font-size: 16px
}

footer .bottom-bar a {
    color: rgba(255,255,255,.65);
    margin-left: 4px
}

footer .bottom-bar a:hover {
    color: #fff
}

footer .bottom-bar .translate-button {
    text-align: left
}

footer .bottom-bar .heart {
    color: #f73f51;
    font-size: 22px
}

footer a {
    color: rgba(255,255,255,.9)
}

footer h2 {
    color: #fff
}

footer h2>span {
    color: #fff
}

.home footer .footer-wrap {
    width: 100%;
    padding: 0
}

.home footer .bottom-bar {
    margin: auto;
    max-width: 1200px;
    padding: 16px 24px;
    border-top: none
}

.home footer .footer-wrap .ant-row {
    width: 100%;
    max-width: 1200px;
    padding: 86px 24px 93px;
    margin: auto
}

@media only screen and (max-width: 767.99px) {
    .aside-container {
        width:240px
    }

    .header .phone-nav-bar {
        width: 16px;
        height: 2px;
        background: #fff;
        display: block;
        border-radius: 2px;
        transition: background .3s cubic-bezier(.645,.045,.355,1)
    }

    .header .phone-nav-bar-wrapper {
        width: 16px;
        height: 16px;
        display: flex;
        align-items: center;
        position: absolute;
        right: 24px;
        top: 0;
        bottom: 0;
        margin: auto;
        cursor: pointer;
        z-index: 1
    }

    .header .phone-nav-bar:after,.header .phone-nav-bar:before {
        content: '';
        background: #fff;
        width: 100%;
        height: 2px;
        display: block;
        position: absolute;
        border-radius: 2px;
        transition: transform .3s cubic-bezier(.645,.045,.355,1);
        transform-origin: 0
    }

    .header .phone-nav-bar:after {
        transform: translateY(6px)
    }

    .header .phone-nav-bar:before {
        transform: translateY(-6px)
    }

    .header .phone-nav-bar.open {
        background: 0 0
    }

    .header .phone-nav-bar.open:after {
        transform: rotate(-45deg) translate(-2px,6px)
    }

    .header .phone-nav-bar.open:before {
        transform: rotate(45deg) translate(-2px,-6px)
    }

    .header .page {
        display: flex;
        justify-content: center
    }

    .popover-menu {
        width: 240px
    }

    .popover-menu button.lang {
        margin: 16px auto;
        float: none
    }

    .popover-menu div.version {
        margin: 32px auto 16px;
        float: none
    }

    .popover-menu .ant-popover-inner {
        overflow: hidden
    }

    .popover-menu .ant-popover-inner-content {
        padding: 0
    }

    #footer {
        text-align: center
    }

    #footer .footer-wrap {
        padding: 40px 24px
    }

    #footer .footer-wrap .ant-row {
        padding: 0
    }

    #footer .footer-wrap .ant-row>div:nth-child(2),#footer .footer-wrap .ant-row>div:nth-child(4) {
        display: none
    }

    #footer .footer-wrap .ant-row>div a {
        font-weight: 300
    }

    #footer .footer-center {
        text-align: center
    }

    #footer h2 {
        margin-top: 16px
    }

    #footer .bottom-bar {
        text-align: center;
        padding: 16px 24px
    }

    #footer .bottom-bar .translate-button {
        width: auto;
        text-align: center;
        margin-bottom: 16px
    }

    #footer .bottom-bar>div>span {
        display: block
    }

    #footer .bottom-bar>div>span:nth-child(1),#footer .bottom-bar>div>span:nth-child(2) {
        display: none
    }
}

.home .page-wrapper,.home-wrapper .home-page-wrapper {
    width: 100%;
    /* position: relative; */
    overflow: hidden
}

.home .page-wrapper .page,.home-wrapper .home-page-wrapper .page,.home .page-wrapper .home-page,.home-wrapper .home-page-wrapper .home-page {
    padding: 128px 24px;
    max-width: 1200px;
    margin: auto
}

.home .page-wrapper h1,.home-wrapper .home-page-wrapper h1 {
    font-size: 32px;
    line-height: 45px;
    margin: 0 auto 64px;
    text-align: center;
    color: #314659
}

.home .header.page-wrapper .page {
    padding: 0 24px
}

/* .home .header {
    box-shadow: 0 0 0 transparent;
    position: absolute;
    width: 100%;
    z-index: 999
} */

.home-button {
    min-width: 136px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    border-radius: 20px;
    display: inline-block;
    font-size: 16px;
    box-sizing: content-box;
    transform: translateY(0);
    box-shadow: 0 0 0 transparent;
    transition: box-shadow .3s cubic-bezier(.215,.61,.355,1),transform .3s cubic-bezier(.215,.61,.355,1)
}

.home-button:hover {
    transform: translateY(-4px);
    box-shadow: 0 4px 12px rgba(16,45,164,.55)
}

@media screen and (max-width: 767px) {
    .home-wrapper .home-page-wrapper .home-page {
        padding:56px 24px
    }

    .home-wrapper .home-page-wrapper .home-page>h1 {
        font-size: 24px;
        margin: 0 auto 32px
    }

    .home-wrapper .home-page-wrapper .home-page>h1.title-h1 {
        margin-bottom: 8px
    }

    .home-wrapper .home-page-wrapper .home-page>p {
        margin-bottom: 32px
    }
}



.page1 .page1-content {
    min-height: 266px
}

.page1-item {
    text-align: center
}

.page1-item-link {
    display: block;
    width: 180px;
    margin: auto
}

.page1-item-link:focus {
    text-decoration: none
}

.page1-item:nth-child(1) .page1-item-img {
    box-shadow: 0 16px 32px rgba(166,55,112,.1)
}

.page1-item:nth-child(1) .page1-item-img:hover {
    box-shadow: 0 20px 36px rgba(166,55,112,.3)
}

.page1-item:nth-child(1) .point-wrapper i {
    background: #eb2f96;
    border-color: #eb2f96
}

.page1-item:nth-child(2) .page1-item-img {
    box-shadow: 0 16px 32px rgba(191,188,21,.1)
}

.page1-item:nth-child(2) .page1-item-img:hover {
    box-shadow: 0 20px 36px rgba(191,188,21,.3)
}

.page1-item:nth-child(2) .point-wrapper i {
    background: #fadb14;
    border-color: #fadb14
}

.page1-item:nth-child(3) .page1-item-img {
    box-shadow: 0 16px 32px rgba(73,101,166,.1)
}

.page1-item:nth-child(3) .page1-item-img:hover {
    box-shadow: 0 20px 36px rgba(73,101,166,.3)
}

.page1-item:nth-child(3) .point-wrapper i {
    background: #2f54eb;
    border-color: #2f54eb
}

.page1-item-img {
    width: 180px;
    height: 180px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    background: #fff;
    position: relative;
    z-index: 1;
    transition: box-shadow .3s cubic-bezier(.215,.61,.355,1),transform .3s cubic-bezier(.215,.61,.355,1)
}

.page1-item-img:hover {
    transform: translateY(-4px)
}

.page1-item-title {
    margin-top: 56px;
    font-size: 20px;
    color: #0d1a26
}

.page1-item p {
    color: #697b8c;
    margin-top: 8px
}

.page1 .point-wrapper {
    position: absolute;
    width: 0;
    left: 50%;
    top: 0;
    z-index: 0
}

.page1 .point-wrapper .point-0 {
    width: 4px;
    height: 4px
}

.page1 .point-wrapper .point-2 {
    width: 10px;
    height: 10px
}

.page1 .point-wrapper .point-ring {
    width: 12px;
    height: 12px;
    border-style: solid;
    border-width: 1px;
    background: 0 0!important
}

.page1 .point-wrapper .point-ring-0 {
    width: 4px;
    height: 4px
}

.page1 .point-wrapper .point-1 {
    width: 12px;
    height: 12px
}

.page1 .point-wrapper .point-3 {
    width: 21px;
    height: 21px
}

.page1 .point-wrapper i {
    display: inline-block;
    border-radius: 100%;
    position: absolute;
    opacity: 0;
    transform: translate(0,30px)
}

.page2 {
    background: #edf1f5
}

.page2 .item-wrapper {
    position: relative;
    border-radius: 4px;
    max-width: 356px;
    margin: 0 auto 48px;
    overflow: hidden;
    background: #fff;
    transform: translateY(0);
    box-shadow: 0 0 0 transparent;
    transition: box-shadow .3s cubic-bezier(.215,.61,.355,1),transform .3s cubic-bezier(.215,.61,.355,1);
    box-shadow: 0 8px 24px rgba(163,177,191,.35)
}

.page2 .item-wrapper:hover {
    transform: translateY(-4px);
    box-shadow: 0 12px 24px rgba(163,177,191,.55)
}

.page2 .item-wrapper .img-wrapper {
    height: 410px;
    overflow: hidden
}

.page2 .item-wrapper .img-wrapper .img {
    width: 100%;
    height: 410px;
    background-size: cover;
    background-position: center top
}

.page2 .item-wrapper .img-wrapper .ant-spin {
    height: 410px;
    max-height: 410px
}

.page2 .item-wrapper p,.page2 .item-wrapper .handle {
    line-height: 48px;
    font-size: 14px;
    height: 48px;
    margin: 0;
    font-weight: 700
}

.page2 .item-wrapper p {
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;
    background: rgba(255,255,255,.75);
    transition: transform .3s cubic-bezier(.215,.61,.355,1)
}

.page2 .item-wrapper .handle {
    background: #2f54eb;
    text-align: center;
    position: absolute;
    bottom: 0;
    width: 100%;
    transform: translateY(100%);
    transition: transform .3s cubic-bezier(.215,.61,.355,1)
}

.page2 .item-wrapper .handle a {
    color: #fff
}

.page2 .item-wrapper .handle a .anticon {
    margin-right: 8px
}

.page2 .item-wrapper:hover {
}

.page2 .item-wrapper:hover p {
    transform: translateY(-100%)
}

.page2 .item-wrapper:hover .handle {
    transform: translateY(0%)
}

.page2 .page2-content-wrapper {
    min-height: 514px;
    position: relative
}

.page2 .page2-content-wrapper .pagination {
    display: flex;
    justify-content: center;
    margin: 24px auto;
    width: 100%
}

.page2 .page2-content-wrapper .tween-group {
    transition: height .45s cubic-bezier(.645,.045,.355,1)
}

.page2 .page2-content-wrapper .tween-group>.tween-one-entering,.page2 .page2-content-wrapper .tween-group>.tween-one-leaving {
    position: absolute;
    width: calc(100% + 48px);
    top: 0;
    left: 0
}

.page2 .bg {
    width: 1401px;
    height: 1843px;
    position: absolute;
    top: 0;
    left: 50%;
    margin-left: -700px
}

.page2 .bg svg {
    overflow: initial
}

.page3 .page h1 {
    margin: 128px auto 64px
}

.page3 .rc-autoresponsive {
    transition: height .3s cubic-bezier(.215,.61,.355,1)
}

.page3 .page3-content {
    min-height: 175px
}

.page3 .page3-content-header {
    margin-bottom: 64px;
    width: 100%;
    min-height: 32px;
    text-align: center
}

.page3 .page3-content-header .ant-radio-button-wrapper {
    transition: border-color .3s cubic-bezier(.215,.61,.355,1),box-shadow .3s cubic-bezier(.215,.61,.355,1),color .3s cubic-bezier(.215,.61,.355,1);
    border-radius: 4px;
    margin: 0 8px 8px;
    width: 100px;
    text-align: center;
    border: 1px solid #ebedf0
}

.page3 .page3-content-header .ant-radio-button-wrapper:before {
    display: none
}

.page3 .page3-content-header .ant-radio-button-wrapper-checked {
    background: #2f54eb;
    border-color: #2f54eb;
    color: #fff
}

.page3 .page3-content-item {
    border: 1px solid #ebedf0;
    border-radius: 4px;
    overflow: hidden
}

.page3 .page3-content-item.queue-anim-entering,.page3 .page3-content-item.queue-anim-leaving {
    transition: none!important
}

.page3 .page3-content-item img {
    display: block
}

.page4 {
    background: #2f54eb;
    color: #fff;
    min-height: 292px;
    text-align: center
}

.page4 p {
    margin: 0 auto 40px;
    font-size: 14px;
    line-height: 20px;
    max-width: 930px
}

.page4 .home-button {
    background: #fff;
    transform: translateY(0);
    box-shadow: 0 0 0 transparent;
    transition: box-shadow .3s cubic-bezier(.215,.61,.355,1),transform .3s cubic-bezier(.215,.61,.355,1);
    box-shadow: 0 4px 8px rgba(0,40,202,.75)
}

.page4 .home-button:hover {
    transform: translateY(-4px);
    box-shadow: 0 8px 16px rgba(0,40,202,.55)
}

.page4 .bg {
    width: 1440px;
    height: 558px;
    position: absolute;
    top: 0;
    left: 50%;
    margin-left: -720px
}

.page4 .bg svg {
    overflow: initial
}

@media only screen and (max-width: 767.99px) {
    .banner {
        height:100vh
    }

    .banner .bg-wrapper #Page-1 {
        transform: translate(-30px,80px)
    }

    .banner .bg-wrapper #Group-8 {
        transform: translate(400px,69px)
    }

    .banner .text-wrapper {
        padding: 0 24px
    }

    .page1 .page1-content {
        margin: 88px auto 0;
        min-height: 990px
    }

    .page1 .page1-content .page1-item {
        margin-bottom: 64px
    }

    .page2 {
        min-height: 330px
    }

    .home-wrapper .page-wrapper .page h1 {
        margin: 72px auto 64px
    }
}

.main-wrapper {
    background: #fff;
    margin: 0;
    border-radius: 2px;
    padding: 40px 0 0;
    position: relative
}

.main-wrapper.landing-move-leave {
    margin-top: 64px
}

.main-container {
    padding: 0 194px 144px 64px;
    margin-left: -1px;
    background: #fff;
    min-height: 800px;
    border-left: 1px solid #e9e9e9;
    position: relative
}

.main-container .markdown {
    background: #fff
}

.main-menu {
    z-index: 1
}

.main-animate-wraper {
    position: relative;
    padding-top: 1px
}

.markdown {
    color: #314659;
    font-size: 14px;
    line-height: 2
}

.highlight {
    line-height: 1.5
}

.markdown .file-logo {
    display: flex;
    align-items: center;
    margin: 40px 0
}

.markdown .file-logo span {
    font-size: 30px;
    color: #aaa;
    margin: 0 20px
}

.markdown .file-logo .ant-logo {
    width: 150px;
    height: 150px
}

.markdown .file-logo .landing-logo {
    width: 150px;
    height: 150px;
    padding: 10px;
    background: #2f54eb;
    border-radius: 8px;
    text-align: center
}

.markdown p>img {
    margin: 2.4em 1em;
    box-shadow: 0 8px 20px rgba(143,168,191,.35)
}

.markdown p>img.img-basic {
    margin: 0;
    box-shadow: none
}

.markdown h1 {
    color: #0d1a26;
    font-weight: 500;
    margin-bottom: 20px;
    margin-top: 8px;
    font-family: Lato,-apple-system,BlinkMacSystemFont,segoe ui,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji,sans-serif;
    font-size: 30px;
    line-height: 38px
}

.markdown h1 .subtitle {
    margin-left: 12px
}

.markdown h2,.markdown h3,.markdown h4,.markdown h5,.markdown h6 {
    color: #0d1a26;
    font-family: Lato,-apple-system,BlinkMacSystemFont,segoe ui,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji,sans-serif;
    margin: 1.6em 0 .6em;
    font-weight: 500;
    clear: both
}

.markdown h2 {
    font-size: 24px;
    line-height: 32px
}

.markdown h3 {
    font-size: 18px
}

.markdown h4 {
    font-size: 16px
}

.markdown h5 {
    font-size: 14px
}

.markdown h6 {
    font-size: 12px
}

.markdown hr {
    height: 1px;
    border: 0;
    background: #e9e9e9;
    margin: 16px 0;
    clear: both
}

.markdown p,.markdown pre {
    margin: 1em 0
}

.markdown ul>li {
    list-style-type: circle;
    margin-left: 20px;
    padding-left: 4px
}

.markdown ul>li:empty {
    display: none
}

.markdown ol>li {
    list-style-type: decimal;
    margin-left: 20px;
    padding-left: 4px
}

.markdown ul>li>p,.markdown ol>li>p {
    margin: .2em 0
}

.markdown code {
    margin: 0 1px;
    background: #f2f4f5;
    padding: .2em .4em;
    border-radius: 3px;
    font-size: .9em;
    border: 1px solid #eee
}

.markdown pre {
    border-radius: 2px;
    background: #f2f4f5;
    font-family: lucida console,Consolas,Monaco,andale mono,ubuntu mono,monospace
}

.markdown pre code {
    border: none;
    background: #f2f4f5;
    margin: 0;
    padding: 0;
    font-size: 14px;
    color: #314659;
    overflow: auto
}

.markdown strong,.markdown b {
    font-weight: 500
}

.markdown>table {
    border-collapse: collapse;
    border-spacing: 0;
    empty-cells: show;
    border: 1px solid #ebedf0;
    width: 100%;
    margin: 8px 0 16px
}

.markdown>table th {
    white-space: nowrap;
    color: #5c6b77;
    font-weight: 500;
    background: rgba(0,0,0,.02)
}

.markdown>table th,.markdown>table td {
    border: 1px solid #ebedf0;
    padding: 16px 24px;
    text-align: left
}

.markdown blockquote {
    font-size: 90%;
    color: #697b8c;
    border-left: 4px solid #ebedf0;
    padding-left: .8em;
    margin: 1em 0
}

.markdown blockquote p {
    margin: 0
}

.markdown .anchor {
    opacity: 0;
    transition: opacity .3s ease;
    margin-left: 8px
}

.markdown .waiting {
    color: #ccc;
    cursor: not-allowed
}

.markdown a.edit-button {
    line-height: 12px;
    display: inline-block;
    margin-left: 10px;
    height: 12px;
    text-decoration: none;
    font-weight: 400
}

.markdown a.edit-button i {
    color: #697b8c
}

.markdown a.edit-button i:hover {
    color: #2f54eb
}

.markdown a.edit-button .anticon {
    display: block;
    font-size: 16px
}

.markdown h1:hover .anchor,.markdown h2:hover .anchor,.markdown h3:hover .anchor,.markdown h4:hover .anchor,.markdown h5:hover .anchor,.markdown h6:hover .anchor {
    opacity: 1;
    display: inline-block
}

.markdown>br,.markdown>p>br {
    clear: both
}

.markdown.api-container table {
    font-size: 14px;
    line-height: 1.5715
}

.markdown.api-container table td:first-child {
    font-weight: 500;
    width: 20%;
    font-family: lucida console,Consolas,Menlo,Courier,monospace
}

.markdown.api-container table td:nth-child(3) {
    width: 22%;
    font-size: 12px;
    font-family: lucida console,Consolas,Menlo,Courier,monospace
}

.markdown.api-container table td:last-child {
    width: 13%;
    font-size: 12px;
    font-family: lucida console,Consolas,Menlo,Courier,monospace
}

.grid-demo .demo-row,[id^=components-grid-demo-] .demo-row,.grid-demo .code-box-demo .demo-row,[id^=components-grid-demo-] .code-box-demo .demo-row {
    background-image: linear-gradient(90deg,#f5f5f5 4.16666667%,transparent 4.16666667%,transparent 8.33333333%,#f5f5f5 8.33333333%,#f5f5f5 12.5%,transparent 12.5%,transparent 16.66666667%,#f5f5f5 16.66666667%,#f5f5f5 20.83333333%,transparent 20.83333333%,transparent 25%,#f5f5f5 25%,#f5f5f5 29.16666667%,transparent 29.16666667%,transparent 33.33333333%,#f5f5f5 33.33333333%,#f5f5f5 37.5%,transparent 37.5%,transparent 41.66666667%,#f5f5f5 41.66666667%,#f5f5f5 45.83333333%,transparent 45.83333333%,transparent 50%,#f5f5f5 50%,#f5f5f5 54.16666667%,transparent 54.16666667%,transparent 58.33333333%,#f5f5f5 58.33333333%,#f5f5f5 62.5%,transparent 62.5%,transparent 66.66666667%,#f5f5f5 66.66666667%,#f5f5f5 70.83333333%,transparent 70.83333333%,transparent 75%,#f5f5f5 75%,#f5f5f5 79.16666667%,transparent 79.16666667%,transparent 83.33333333%,#f5f5f5 83.33333333%,#f5f5f5 87.5%,transparent 87.5%,transparent 91.66666667%,#f5f5f5 91.66666667%,#f5f5f5 95.83333333%,transparent 95.83333333%);
    overflow: hidden;
    margin-bottom: 8px
}

.grid-demo .ant-row-flex,[id^=components-grid-demo-] .ant-row-flex,.grid-demo .code-box-demo .ant-row-flex,[id^=components-grid-demo-] .code-box-demo .ant-row-flex {
    background: #f5f5f5
}

.grid-demo .ant-row>div,[id^=components-grid-demo-] .ant-row>div,.grid-demo .code-box-demo .ant-row>div,[id^=components-grid-demo-] .code-box-demo .ant-row>div,.grid-demo .ant-row-flex>div,[id^=components-grid-demo-] .ant-row-flex>div,.grid-demo .code-box-demo .ant-row-flex>div,[id^=components-grid-demo-] .code-box-demo .ant-row-flex>div {
    padding: 5px 0;
    text-align: center;
    border-radius: 0;
    min-height: 30px;
    margin-top: 8px;
    margin-bottom: 8px;
    color: #fff
}

.grid-demo .code-box-demo .ant-row>div:not(.gutter-row),[id^=components-grid-demo-] .code-box-demo .ant-row>div:not(.gutter-row),.grid-demo .code-box-demo .ant-row-flex>div:not(.gutter-row),[id^=components-grid-demo-] .code-box-demo .ant-row-flex>div:not(.gutter-row) {
    background: #00a0e9;
    padding: 16px 0
}

.grid-demo .code-box-demo .ant-row>div:not(.gutter-row):nth-child(2n+1),[id^=components-grid-demo-] .code-box-demo .ant-row>div:not(.gutter-row):nth-child(2n+1),.grid-demo .code-box-demo .ant-row-flex>div:not(.gutter-row):nth-child(2n+1),[id^=components-grid-demo-] .code-box-demo .ant-row-flex>div:not(.gutter-row):nth-child(2n+1) {
    background: rgba(0,160,233,.7)
}

.grid-demo .ant-row .demo-col,[id^=components-grid-demo-] .ant-row .demo-col,.grid-demo .code-box-demo .ant-row .demo-col,[id^=components-grid-demo-] .code-box-demo .ant-row .demo-col {
    text-align: center;
    padding: 30px 0;
    color: #fff;
    font-size: 18px;
    border: none;
    margin-top: 0;
    margin-bottom: 0
}

.grid-demo .ant-row .demo-col-1,[id^=components-grid-demo-] .ant-row .demo-col-1 {
    background: rgba(0,160,233,.7)
}

.grid-demo .ant-row .demo-col-2,[id^=components-grid-demo-] .ant-row .demo-col-2,.grid-demo .code-box-demo .ant-row .demo-col-2,[id^=components-grid-demo-] .code-box-demo .ant-row .demo-col-2 {
    background: rgba(0,160,233,.5)
}

.grid-demo .ant-row .demo-col-3,[id^=components-grid-demo-] .ant-row .demo-col-3,.grid-demo .code-box-demo .ant-row .demo-col-3,[id^=components-grid-demo-] .code-box-demo .ant-row .demo-col-3 {
    background: rgba(255,255,255,.2);
    color: #999
}

.grid-demo .ant-row .demo-col-4,[id^=components-grid-demo-] .ant-row .demo-col-4,.grid-demo .code-box-demo .ant-row .demo-col-4,[id^=components-grid-demo-] .code-box-demo .ant-row .demo-col-4 {
    background: rgba(0,160,233,.6)
}

.grid-demo .ant-row .demo-col-5,[id^=components-grid-demo-] .ant-row .demo-col-5,.grid-demo .code-box-demo .ant-row .demo-col-5,[id^=components-grid-demo-] .code-box-demo .ant-row .demo-col-5 {
    background: rgba(255,255,255,.5);
    color: #999
}

.grid-demo .code-box-demo .height-100,[id^=components-grid-demo-] .code-box-demo .height-100 {
    height: 100px;
    line-height: 100px
}

.grid-demo .code-box-demo .height-50,[id^=components-grid-demo-] .code-box-demo .height-50 {
    height: 50px;
    line-height: 50px
}

.grid-demo .code-box-demo .height-120,[id^=components-grid-demo-] .code-box-demo .height-120 {
    height: 120px;
    line-height: 120px
}

.grid-demo .code-box-demo .height-80,[id^=components-grid-demo-] .code-box-demo .height-80 {
    height: 80px;
    line-height: 80px
}

.markdown ul.ant-timeline li.ant-timeline-item {
    list-style: none;
    margin: 0;
    padding: 0 0 30px
}

.markdown ul.ant-timeline li.ant-timeline-item .ant-timeline-item-content {
    font-size: 14px;
    padding-left: 32px;
    position: relative;
    top: -14px
}

.markdown ul.ant-timeline li.ant-timeline-item .ant-timeline-item-content>h2 {
    margin-top: 0;
    padding-top: .5px
}

.markdown ul.ant-timeline li.ant-timeline-item:first-child {
    margin-top: 40px
}

pre code {
    display: block;
    background: #fff;
    color: #314659;
    line-height: 1.5;
    border: 1px solid #e9e9e9;
    padding: 16px 32px;
    border-radius: 2px;
    font-size: 14px;
    white-space: pre;
    font-family: lucida console,Consolas,Monaco,andale mono,ubuntu mono,monospace
}

code[class*=language-],pre[class*=language-] {
    color: #000;
    background: 0 0;
    font-family: lucida console,Consolas,Monaco,andale mono,ubuntu mono,monospace;
    direction: ltr;
    text-align: left;
    white-space: pre;
    word-spacing: normal;
    word-break: normal;
    word-wrap: normal;
    line-height: 1.5;
    -moz-tab-size: 4;
    -o-tab-size: 4;
    tab-size: 4;
    -webkit-hyphens: none;
    -ms-hyphens: none;
    hyphens: none
}

pre[class*=language-]::-moz-selection,pre[class*=language-] ::-moz-selection,code[class*=language-]::-moz-selection,code[class*=language-] ::-moz-selection {
    text-shadow: none;
    background: #b3d4fc
}

pre[class*=language-]::-moz-selection,pre[class*=language-] ::-moz-selection,code[class*=language-]::-moz-selection,code[class*=language-] ::-moz-selection {
    text-shadow: none;
    background: #b3d4fc
}

pre[class*=language-]::selection,pre[class*=language-] ::selection,code[class*=language-]::selection,code[class*=language-] ::selection {
    text-shadow: none;
    background: #b3d4fc
}

@media print {
    code[class*=language-],pre[class*=language-] {
        text-shadow: none
    }
}

pre[class*=language-] {
    padding: 12px 20px;
    margin: 16px 0;
    overflow: auto
}

:not(pre)>code[class*=language-],pre[class*=language-] {
    background: #f2f4f5
}

:not(pre)>code[class*=language-] {
    padding: .1em;
    border-radius: .3em;
    white-space: normal
}

.token.comment,.token.prolog,.token.doctype,.token.cdata {
    color: #708090
}

.token.punctuation {
    color: #999
}

.namespace {
    opacity: .7
}

.token.property,.token.tag,.token.boolean,.token.number,.token.constant,.token.symbol,.token.deleted {
    color: #f81d22
}

.token.selector,.token.attr-name,.token.string,.token.char,.token.builtin,.token.inserted {
    color: #0b8235
}

.token.operator,.token.entity,.token.url,.language-css .token.string,.style .token.string {
    color: #0b8235
}

.token.atrule,.token.attr-value,.token.keyword {
    color: #008dff
}

.token.function {
    color: #f81d22
}

.token.regex,.token.important,.token.variable {
    color: #e90
}

.token.important,.token.bold {
    font-weight: 700
}

.token.italic {
    font-style: italic
}

.token.entity {
    cursor: help
}

.prev-next-nav {
    position: absolute;
    bottom: 0;
    left: 0;
    width: calc(100% - 194px - 64px);
    margin-left: 64px;
    overflow: hidden;
    font-size: 14px;
    border-top: 1px solid #ebedf0
}

.prev-next-nav>.prev-page,.prev-next-nav>.next-page {
    padding: 0 24px;
    float: left;
    line-height: 72px;
    height: 72px;
    text-decoration: none
}

.prev-next-nav>a.prev-page i {
    transform: translateX(0);
    transition: transform .3s cubic-bezier(.215,.61,.355,1)
}

.prev-next-nav>a.prev-page:hover i {
    color: #2f54eb;
    transform: translateX(-3px)
}

.prev-next-nav>.next-page {
    text-align: right;
    float: right
}

.prev-next-nav>.next-page i {
    transform: translateX(0);
    transition: transform .3s cubic-bezier(.215,.61,.355,1)
}

.prev-next-nav>.next-page:hover i {
    color: #2f54eb;
    transform: translateX(3px)
}

.prev-next-nav .chinese {
    margin-left: .5em
}

.toc-affix {
    position: absolute;
    top: 8px;
    right: -174px
}

.toc-affix .ant-affix {
    background: #fff
}

.toc-affix .ant-anchor {
    font-size: 12px;
    margin: 16px 0;
    border-left: 1px solid #ebedf0;
    list-style: none
}

.toc-affix .ant-anchor a {
    padding-left: 16px;
    display: block;
    transition: all .3s ease;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #314659;
    width: 110px
}

.toc-affix .ant-anchor .ant-anchor-link-active a {
    border-color: #2f54eb;
    color: #2f54eb
}

.toc-affix .ant-anchor .ant-anchor-ink:before {
    display: none
}

.toc-affix .ant-anchor .ant-anchor-link-title {
    padding: 0
}

.toc-affix-bottom {
    position: absolute;
    bottom: 88px;
    right: 20px
}

.toc-affix-bottom .ant-affix {
    background: #fff
}

@media only screen and (max-width: 767.99px) {
    .markdown .file-logo .ant-logo,.markdown .file-logo .landing-logo {
        width:100px;
        height: 100px
    }

    .toc {
        display: none
    }

    .main-container {
        padding: 0 24px 144px
    }

    .prev-next-nav {
        width: calc(100% - 48px);
        margin: 0 24px
    }
}

.preview-image-boxes {
    display: flex;
    float: right;
    margin: 0 0 70px 64px;
    width: 40%;
    clear: both
}

.preview-image-boxes-with-carousel {
    width: 420px
}

.preview-image-boxes-with-carousel .preview-image-box img {
    padding: 0
}

.preview-image-boxes+.preview-image-boxes {
    margin-top: -35px
}

.preview-image-box {
    width: 100%;
    float: left
}

.preview-image-box+.preview-image-box {
    margin-left: 24px
}

.preview-image-wrapper {
    background: #f2f4f5;
    padding: 16px;
    display: inline-block;
    text-align: center;
    width: 100%;
    position: relative
}

.preview-image-wrapper.video {
    padding: 0;
    background: 0;
    display: block
}

.preview-image-wrapper video {
    width: 100%;
    display: block
}

.preview-image-wrapper video+svg {
    position: absolute;
    top: 0;
    left: 0
}

.preview-image-wrapper.good:after {
    content: '';
    width: 100%;
    height: 3px;
    background: #2f54eb;
    display: block;
    position: absolute;
    bottom: 0;
    left: 0
}

.preview-image-wrapper.bad:after {
    content: '';
    width: 100%;
    height: 3px;
    background: #ff4d4f;
    display: block;
    position: absolute;
    bottom: 0;
    left: 0
}

.preview-image-title {
    font-size: 12px;
    margin-top: 5px;
    color: #314659
}

.preview-image-description {
    font-size: 12px;
    margin-top: 2px;
    color: #697b8c;
    line-height: 1.5
}

.preview-image-description hr {
    margin: 2px 0;
    border: 0;
    background: 0 0
}

.preview-image-box img {
    cursor: pointer;
    max-width: 100%;
    transition: all .3s;
    background: #fff;
    padding: 12px;
    border-radius: 2px
}

.preview-image-box img.no-padding {
    padding: 0;
    background: 0 0
}

.preview-image-boxes.preview-image-boxes-with-carousel img {
    padding: 0;
    box-shadow: 0 1px 0 0 #ddd,0 3px 0 0 #fff,0 4px 0 0 #ddd,0 6px 0 0 #fff,0 7px 0 0 #ddd
}

.preview-image-box img:hover {
    box-shadow: 1px 1px 6px rgba(0,0,0,.3)
}

.image-modal {
    text-align: center
}

.image-modal-container {
    position: relative;
    text-align: center
}

.image-modal .ant-carousel .slick-slider {
    padding-bottom: 24px
}

.image-modal .ant-carousel .slick-slider img {
    display: inline;
    max-width: 100%
}

.image-modal .ant-carousel .slick-dots {
    bottom: 4px
}

.image-modal .ant-carousel .slick-dots li button {
    background: #888
}

.image-modal .image-modal-single.slick-slider {
    padding-bottom: 0
}

.image-modal .image-modal-single .slick-dots {
    display: none!important
}

.transition-video-player {
    float: right;
    padding: 0 0 70px 20px;
    width: 600px
}

.transition-video-player .preview-image-wrapper {
    padding: 0
}

.ant-modal {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: #314659;
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: 'tnum';
    position: relative;
    top: 100px;
    width: auto;
    margin: 0 auto;
    padding-bottom: 24px;
    pointer-events: none
}

.ant-modal-wrap {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
    overflow: auto;
    outline: 0;
    -webkit-overflow-scrolling: touch
}

.ant-modal-title {
    margin: 0;
    color: rgba(0,0,0,.85);
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    word-wrap: break-word
}

.ant-modal-content {
    position: relative;
    background-color: #fff;
    background-clip: padding-box;
    border: 0;
    border-radius: 2px;
    box-shadow: 0 3px 6px -4px rgba(0,0,0,.12),0 6px 16px 0 rgba(0,0,0,.08),0 9px 28px 8px rgba(0,0,0,.05);
    pointer-events: auto
}

.ant-modal-close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 10;
    padding: 0;
    color: rgba(0,0,0,.45);
    font-weight: 700;
    line-height: 1;
    text-decoration: none;
    background: 0 0;
    border: 0;
    outline: 0;
    cursor: pointer;
    transition: color .3s
}

.ant-modal-close-x {
    display: block;
    width: 56px;
    height: 56px;
    font-size: 16px;
    font-style: normal;
    line-height: 56px;
    text-align: center;
    text-transform: none;
    text-rendering: auto
}

.ant-modal-close:focus,.ant-modal-close:hover {
    color: rgba(0,0,0,.75);
    text-decoration: none
}

.ant-modal-header {
    padding: 16px 24px;
    color: #314659;
    background: #fff;
    border-bottom: 1px solid #f0f0f0;
    border-radius: 2px 2px 0 0
}

.ant-modal-body {
    padding: 24px;
    font-size: 14px;
    line-height: 1.5715;
    word-wrap: break-word
}

.ant-modal-footer {
    padding: 10px 16px;
    text-align: right;
    background: 0 0;
    border-top: 1px solid #f0f0f0;
    border-radius: 0 0 2px 2px
}

.ant-modal-footer button+button {
    margin-bottom: 0;
    margin-left: 8px
}

.ant-modal.zoom-enter,.ant-modal.zoom-appear {
    transform: none;
    opacity: 0;
    -webkit-animation-duration: .3s;
    animation-duration: .3s;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.ant-modal-mask {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
    height: 100%;
    background-color: rgba(0,0,0,.45);
    filter: alpha(opacity=50)
}

.ant-modal-mask-hidden {
    display: none
}

.ant-modal-open {
    overflow: hidden
}

.ant-modal-centered {
    text-align: center
}

.ant-modal-centered::before {
    display: inline-block;
    width: 0;
    height: 100%;
    vertical-align: middle;
    content: ''
}

.ant-modal-centered .ant-modal {
    top: 0;
    display: inline-block;
    text-align: left;
    vertical-align: middle
}

@media(max-width: 767px) {
    .ant-modal {
        max-width:calc(100vw - 16px);
        margin: 8px auto
    }

    .ant-modal-centered .ant-modal {
        flex: 1
    }
}

.ant-modal-confirm .ant-modal-header {
    display: none
}

.ant-modal-confirm .ant-modal-close {
    display: none
}

.ant-modal-confirm .ant-modal-body {
    padding: 32px 32px 24px
}

.ant-modal-confirm-body-wrapper::before {
    display: table;
    content: ''
}

.ant-modal-confirm-body-wrapper::after {
    display: table;
    clear: both;
    content: ''
}

.ant-modal-confirm-body .ant-modal-confirm-title {
    display: block;
    overflow: hidden;
    color: rgba(0,0,0,.85);
    font-weight: 500;
    font-size: 16px;
    line-height: 1.4
}

.ant-modal-confirm-body .ant-modal-confirm-content {
    margin-top: 8px;
    color: #314659;
    font-size: 14px
}

.ant-modal-confirm-body>.anticon {
    float: left;
    margin-right: 16px;
    font-size: 22px
}

.ant-modal-confirm-body>.anticon+.ant-modal-confirm-title+.ant-modal-confirm-content {
    margin-left: 38px
}

.ant-modal-confirm .ant-modal-confirm-btns {
    float: right;
    margin-top: 24px
}

.ant-modal-confirm .ant-modal-confirm-btns button+button {
    margin-bottom: 0;
    margin-left: 8px
}

.ant-modal-confirm-error .ant-modal-confirm-body>.anticon {
    color: #ff4d4f
}

.ant-modal-confirm-warning .ant-modal-confirm-body>.anticon,.ant-modal-confirm-confirm .ant-modal-confirm-body>.anticon {
    color: #faad14
}

.ant-modal-confirm-info .ant-modal-confirm-body>.anticon {
    color: #1890ff
}

.ant-modal-confirm-success .ant-modal-confirm-body>.anticon {
    color: #52c41a
}

.ant-modal-wrap-rtl {
    direction: rtl
}

.ant-modal-wrap-rtl .ant-modal-close {
    right: initial;
    left: 0
}

.ant-modal-wrap-rtl .ant-modal-footer {
    text-align: left
}

.ant-modal-wrap-rtl .ant-modal-footer button+button {
    margin-right: 8px;
    margin-left: 0
}

.ant-modal-wrap-rtl.ant-modal-centered .ant-modal {
    text-align: right
}

.ant-notification {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: #314659;
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    -webkit-font-feature-settings: 'tnum';
    font-feature-settings: 'tnum';
    position: fixed;
    z-index: 1010;
    width: 384px;
    max-width: calc(100vw - 32px);
    margin-right: 24px
}

.ant-notification-topLeft,.ant-notification-bottomLeft {
    margin-right: 0;
    margin-left: 24px
}

.ant-notification-topLeft .ant-notification-fade-enter.ant-notification-fade-enter-active,.ant-notification-bottomLeft .ant-notification-fade-enter.ant-notification-fade-enter-active,.ant-notification-topLeft .ant-notification-fade-appear.ant-notification-fade-appear-active,.ant-notification-bottomLeft .ant-notification-fade-appear.ant-notification-fade-appear-active {
    -webkit-animation-name: NotificationLeftFadeIn;
    animation-name: NotificationLeftFadeIn
}

.ant-notification-close-icon {
    font-size: 14px;
    cursor: pointer
}

.ant-notification-hook-holder,.ant-notification-notice {
    position: relative;
    margin-bottom: 16px;
    overflow: hidden;
    background: #fff;
    border-radius: 2px;
    -webkit-box-shadow: 0 3px 6px -4px rgba(0,0,0,.12),0 6px 16px 0 rgba(0,0,0,.08),0 9px 28px 8px rgba(0,0,0,.05);
    box-shadow: 0 3px 6px -4px rgba(0,0,0,.12),0 6px 16px 0 rgba(0,0,0,.08),0 9px 28px 8px rgba(0,0,0,.05)
}

.ant-notification-hook-holder>.ant-notification-notice {
    margin-bottom: 0;
    -webkit-box-shadow: none;
    box-shadow: none
}

.ant-notification-notice {
    padding: 16px 24px;
    line-height: 1.5715
}

.ant-notification-notice-message {
    display: inline-block;
    margin-bottom: 8px;
    color: rgba(0,0,0,.85);
    font-size: 16px;
    line-height: 24px
}

.ant-notification-notice-message-single-line-auto-margin {
    display: block;
    width: calc(384px - 24px * 2 - 24px - 48px - 100%);
    max-width: 4px;
    background-color: transparent;
    pointer-events: none
}

.ant-notification-notice-message-single-line-auto-margin::before {
    display: block;
    content: ''
}

.ant-notification-notice-description {
    font-size: 14px
}

.ant-notification-notice-closable .ant-notification-notice-message {
    padding-right: 24px
}

.ant-notification-notice-with-icon .ant-notification-notice-message {
    margin-bottom: 4px;
    margin-left: 48px;
    font-size: 16px
}

.ant-notification-notice-with-icon .ant-notification-notice-description {
    margin-left: 48px;
    font-size: 14px
}

.ant-notification-notice-icon {
    position: absolute;
    margin-left: 4px;
    font-size: 24px;
    line-height: 24px
}

.anticon.ant-notification-notice-icon-success {
    color: #52c41a
}

.anticon.ant-notification-notice-icon-info {
    color: #1890ff
}

.anticon.ant-notification-notice-icon-warning {
    color: #faad14
}

.anticon.ant-notification-notice-icon-error {
    color: #ff4d4f
}

.ant-notification-notice-close {
    position: absolute;
    top: 16px;
    right: 22px;
    color: rgba(0,0,0,.45);
    outline: none
}

.ant-notification-notice-close:hover {
    color: rgba(0,0,0,.67)
}

.ant-notification-notice-btn {
    float: right;
    margin-top: 16px
}

.ant-notification .notification-fade-effect {
    -webkit-animation-duration: .24s;
    animation-duration: .24s;
    -webkit-animation-timing-function: cubic-bezier(.645,.045,.355,1);
    animation-timing-function: cubic-bezier(.645,.045,.355,1);
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both
}

.ant-notification-fade-enter,.ant-notification-fade-appear {
    opacity: 0;
    -webkit-animation-duration: .24s;
    animation-duration: .24s;
    -webkit-animation-timing-function: cubic-bezier(.645,.045,.355,1);
    animation-timing-function: cubic-bezier(.645,.045,.355,1);
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-play-state: paused;
    animation-play-state: paused
}

.ant-notification-fade-leave {
    -webkit-animation-duration: .24s;
    animation-duration: .24s;
    -webkit-animation-timing-function: cubic-bezier(.645,.045,.355,1);
    animation-timing-function: cubic-bezier(.645,.045,.355,1);
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-duration: .2s;
    animation-duration: .2s;
    -webkit-animation-play-state: paused;
    animation-play-state: paused
}

.ant-notification-fade-enter.ant-notification-fade-enter-active,.ant-notification-fade-appear.ant-notification-fade-appear-active {
    -webkit-animation-name: NotificationFadeIn;
    animation-name: NotificationFadeIn;
    -webkit-animation-play-state: running;
    animation-play-state: running
}

.ant-notification-fade-leave.ant-notification-fade-leave-active {
    -webkit-animation-name: NotificationFadeOut;
    animation-name: NotificationFadeOut;
    -webkit-animation-play-state: running;
    animation-play-state: running
}

@-webkit-keyframes NotificationFadeIn {
    0% {
        left: 384px;
        opacity: 0
    }

    100% {
        left: 0;
        opacity: 1
    }
}

@keyframes NotificationFadeIn {
    0% {
        left: 384px;
        opacity: 0
    }

    100% {
        left: 0;
        opacity: 1
    }
}

@-webkit-keyframes NotificationLeftFadeIn {
    0% {
        right: 384px;
        opacity: 0
    }

    100% {
        right: 0;
        opacity: 1
    }
}

@keyframes NotificationLeftFadeIn {
    0% {
        right: 384px;
        opacity: 0
    }

    100% {
        right: 0;
        opacity: 1
    }
}

@-webkit-keyframes NotificationFadeOut {
    0% {
        max-height: 150px;
        margin-bottom: 16px;
        padding-top: 16px 24px;
        padding-bottom: 16px 24px;
        opacity: 1
    }

    100% {
        max-height: 0;
        margin-bottom: 0;
        padding-top: 0;
        padding-bottom: 0;
        opacity: 0
    }
}

@keyframes NotificationFadeOut {
    0% {
        max-height: 150px;
        margin-bottom: 16px;
        padding-top: 16px 24px;
        padding-bottom: 16px 24px;
        opacity: 1
    }

    100% {
        max-height: 0;
        margin-bottom: 0;
        padding-top: 0;
        padding-bottom: 0;
        opacity: 0
    }
}

.ant-popover {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: #314659;
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    -webkit-font-feature-settings: 'tnum';
    font-feature-settings: 'tnum';
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1030;
    font-weight: 400;
    white-space: normal;
    text-align: left;
    cursor: auto;
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text
}

.ant-popover::after {
    position: absolute;
    background: rgba(255,255,255,.01);
    content: ''
}

.ant-popover-hidden {
    display: none
}

.ant-popover-placement-top,.ant-popover-placement-topLeft,.ant-popover-placement-topRight {
    padding-bottom: 10px
}

.ant-popover-placement-right,.ant-popover-placement-rightTop,.ant-popover-placement-rightBottom {
    padding-left: 10px
}

.ant-popover-placement-bottom,.ant-popover-placement-bottomLeft,.ant-popover-placement-bottomRight {
    padding-top: 10px
}

.ant-popover-placement-left,.ant-popover-placement-leftTop,.ant-popover-placement-leftBottom {
    padding-right: 10px
}

.ant-popover-inner {
    background-color: #fff;
    background-clip: padding-box;
    border-radius: 2px;
    -webkit-box-shadow: 0 3px 6px -4px rgba(0,0,0,.12),0 6px 16px 0 rgba(0,0,0,.08),0 9px 28px 8px rgba(0,0,0,.05);
    box-shadow: 0 3px 6px -4px rgba(0,0,0,.12),0 6px 16px 0 rgba(0,0,0,.08),0 9px 28px 8px rgba(0,0,0,.05);
    -webkit-box-shadow: 0 0 8px rgba(0,0,0,.15) \9;
    box-shadow: 0 0 8px rgba(0,0,0,.15) \9
}

@media screen and (-ms-high-contrast:active),(-ms-high-contrast:none) {
    .ant-popover {
    }

    .ant-popover-inner {
        -webkit-box-shadow: 0 3px 6px -4px rgba(0,0,0,.12),0 6px 16px 0 rgba(0,0,0,.08),0 9px 28px 8px rgba(0,0,0,.05);
        box-shadow: 0 3px 6px -4px rgba(0,0,0,.12),0 6px 16px 0 rgba(0,0,0,.08),0 9px 28px 8px rgba(0,0,0,.05)
    }
}

.ant-popover-title {
    min-width: 177px;
    min-height: 32px;
    margin: 0;
    padding: 5px 16px 4px;
    color: rgba(0,0,0,.85);
    font-weight: 500;
    border-bottom: 1px solid #f0f0f0
}

.ant-popover-inner-content {
    padding: 12px 16px;
    color: #314659
}

.ant-popover-message {
    position: relative;
    padding: 4px 0 12px;
    color: #314659;
    font-size: 14px
}

.ant-popover-message>.anticon {
    position: absolute;
    top: 8px;
    color: #faad14;
    font-size: 14px
}

.ant-popover-message-title {
    padding-left: 22px
}

.ant-popover-buttons {
    margin-bottom: 4px;
    text-align: right
}

.ant-popover-buttons button {
    margin-left: 8px
}

.ant-popover-arrow {
    position: absolute;
    display: block;
    width: 8.48528137px;
    height: 8.48528137px;
    background: 0 0;
    border-style: solid;
    border-width: 4.24264069px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg)
}

.ant-popover-placement-top>.ant-popover-content>.ant-popover-arrow,.ant-popover-placement-topLeft>.ant-popover-content>.ant-popover-arrow,.ant-popover-placement-topRight>.ant-popover-content>.ant-popover-arrow {
    bottom: 6.2px;
    border-top-color: transparent;
    border-right-color: #fff;
    border-bottom-color: #fff;
    border-left-color: transparent;
    -webkit-box-shadow: 3px 3px 7px rgba(0,0,0,.07);
    box-shadow: 3px 3px 7px rgba(0,0,0,.07)
}

.ant-popover-placement-top>.ant-popover-content>.ant-popover-arrow {
    left: 50%;
    -webkit-transform: translateX(-50%) rotate(45deg);
    transform: translateX(-50%) rotate(45deg)
}

.ant-popover-placement-topLeft>.ant-popover-content>.ant-popover-arrow {
    left: 16px
}

.ant-popover-placement-topRight>.ant-popover-content>.ant-popover-arrow {
    right: 16px
}

.ant-popover-placement-right>.ant-popover-content>.ant-popover-arrow,.ant-popover-placement-rightTop>.ant-popover-content>.ant-popover-arrow,.ant-popover-placement-rightBottom>.ant-popover-content>.ant-popover-arrow {
    left: 6px;
    border-top-color: transparent;
    border-right-color: transparent;
    border-bottom-color: #fff;
    border-left-color: #fff;
    -webkit-box-shadow: -3px 3px 7px rgba(0,0,0,.07);
    box-shadow: -3px 3px 7px rgba(0,0,0,.07)
}

.ant-popover-placement-right>.ant-popover-content>.ant-popover-arrow {
    top: 50%;
    -webkit-transform: translateY(-50%) rotate(45deg);
    transform: translateY(-50%) rotate(45deg)
}

.ant-popover-placement-rightTop>.ant-popover-content>.ant-popover-arrow {
    top: 12px
}

.ant-popover-placement-rightBottom>.ant-popover-content>.ant-popover-arrow {
    bottom: 12px
}

.ant-popover-placement-bottom>.ant-popover-content>.ant-popover-arrow,.ant-popover-placement-bottomLeft>.ant-popover-content>.ant-popover-arrow,.ant-popover-placement-bottomRight>.ant-popover-content>.ant-popover-arrow {
    top: 6px;
    border-top-color: #fff;
    border-right-color: transparent;
    border-bottom-color: transparent;
    border-left-color: #fff;
    -webkit-box-shadow: -2px -2px 5px rgba(0,0,0,.06);
    box-shadow: -2px -2px 5px rgba(0,0,0,.06)
}

.ant-popover-placement-bottom>.ant-popover-content>.ant-popover-arrow {
    left: 50%;
    -webkit-transform: translateX(-50%) rotate(45deg);
    transform: translateX(-50%) rotate(45deg)
}

.ant-popover-placement-bottomLeft>.ant-popover-content>.ant-popover-arrow {
    left: 16px
}

.ant-popover-placement-bottomRight>.ant-popover-content>.ant-popover-arrow {
    right: 16px
}

.ant-popover-placement-left>.ant-popover-content>.ant-popover-arrow,.ant-popover-placement-leftTop>.ant-popover-content>.ant-popover-arrow,.ant-popover-placement-leftBottom>.ant-popover-content>.ant-popover-arrow {
    right: 6px;
    border-top-color: #fff;
    border-right-color: #fff;
    border-bottom-color: transparent;
    border-left-color: transparent;
    -webkit-box-shadow: 3px -3px 7px rgba(0,0,0,.07);
    box-shadow: 3px -3px 7px rgba(0,0,0,.07)
}

.ant-popover-placement-left>.ant-popover-content>.ant-popover-arrow {
    top: 50%;
    -webkit-transform: translateY(-50%) rotate(45deg);
    transform: translateY(-50%) rotate(45deg)
}

.ant-popover-placement-leftTop>.ant-popover-content>.ant-popover-arrow {
    top: 12px
}

.ant-popover-placement-leftBottom>.ant-popover-content>.ant-popover-arrow {
    bottom: 12px
}

.ant-popover-rtl {
    direction: rtl;
    text-align: right
}

.ant-popover-rtl .ant-popover-message-title {
    padding-right: 22px;
    padding-left: 16px
}

.ant-popover-rtl .ant-popover-buttons {
    text-align: left
}

.ant-popover-rtl .ant-popover-buttons button {
    margin-right: 8px;
    margin-left: 0
}

.markdown .cases-content-wrapper {
    padding: 10px;
    background: #f2f4f5;
    border-radius: 4px;
    transform: translateY(0);
    box-shadow: 0 0 0 transparent;
    transition: box-shadow .3s cubic-bezier(.215,.61,.355,1),transform .3s cubic-bezier(.215,.61,.355,1);
    display: block;
    will-change: transform;
    margin-bottom: 24px
}

.markdown .cases-content-wrapper:hover {
    transform: translateY(-4px);
    box-shadow: 0 4px 12px rgba(102,102,102,.55)
}

.markdown .cases-content-wrapper .img-wrapper {
    border-radius: 2px;
    overflow: hidden
}

.markdown .cases-content-wrapper h3 {
    font-size: 16px;
    margin: 16px auto 8px;
    line-height: 1.5
}

.markdown .cases-content-wrapper p {
    margin: auto;
    color: #314659
}

.resource-wrapper {
    margin-bottom: 24px
}

.resource-cards {
    padding: 10px;
    background: #fff;
    border-radius: 4px;
    border: 1px solid #f0f0f0;
    transform: translateY(0);
    box-shadow: 0 0 0 transparent;
    transition: box-shadow .3s cubic-bezier(.215,.61,.355,1),transform .3s cubic-bezier(.215,.61,.355,1);
    display: block
}

.resource-cards:hover {
    transform: translateY(-4px);
    box-shadow: 0 4px 12px rgba(153,153,153,.55)
}

.resource-cards .img-wrapper {
    border-radius: 2px;
    overflow: hidden;
    height: 310px;
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-position: top center;
    background-color: #f0f0f0
}

.resource-cards .text-wrapper h3 {
    margin: 16px auto 8px;
    line-height: 1.5
}

.resource-cards .text-wrapper p {
    font-size: 12px;
    margin: auto;
    color: rgba(49,70,89,.75)
}
