.header-placeholder {
  /* background: #05175f; */
  background: transparent;
  width: 100%;
  height: 64px;
  position: absolute;
  top: 0;
  left: 0
}

.header {
  height: 64px;
  line-height: 64px;
  background: transparent;
  /* background: #05175f; */
  /* box-shadow: 0 4px 12px rgba(138,166,195,.45); */
  transition: box-shadow .45s cubic-bezier(.215,.61,.355,1);
  box-shadow: 0 0 0 transparent;
  position: fixed;
  z-index: 1000;
  width: 100%;
  top: 0;
  /* background-image: url('../../../assets/bg-home.jpg');
  background-size: cover; */
}

.header.scrolled {
  background-color: white;
  color: black;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Example shadow */
}

.header .logo {
  display: flex;
  align-items: center;
  color: #fff;
  font-size: 16px;
  font-family: sf ui display,helvetica neue for number,-apple-system,BlinkMacSystemFont,segoe ui,Roboto,pingfang sc,hiragino sans gb,microsoft yahei,helvetica neue,Helvetica,Arial,sans-serif;
  text-decoration: none;
  justify-content: center; /* Optional, if you want horizontal centering */
}

.header .logo.scrolled {
  color: black;
}

.header .menuItem {
  color: white;
}
.header .menuItem.scrolled {
  color: black;
}


.header .menuItem:hover {
  color: grey !important;
}

.header .logo img {
  width: 24px;
  height: 24px;
  margin-right: 8px
}

.header .menu {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background: transparent
  /* background: #05175f */
}

.header .menu .ant-menu-horizontal {
  color: #fff;
  background: 0 0;
  border-bottom: none;
  transition: none;
  height: 64px
}

.header .menu .ant-menu-horizontal>.ant-menu-item {
  line-height: 60px;
  height: 62px;
  border-top: 2px solid transparent;
  box-sizing: content-box;
  border-bottom: none
}

.header .menu .ant-menu-horizontal>.ant-menu-item:hover,.header .menu .ant-menu-horizontal>.ant-menu-item.ant-menu-item-selected {
  border-top-color: #fff
}

.header .menu .ant-menu-horizontal a {
  color: #fff
}

.header .menu .gitbtn {
  display: inline-block;
  border: 1px solid #fff;
  border-radius: 28px;
  height: 28px;
  line-height: 28px;
  color: #fff;
  padding: 0 16px;
  margin-left: 20px;
  box-sizing: content-box
}

/* .home .header {
    box-shadow: 0 0 0 transparent;
    position: absolute;
    width: 100%;
    z-index: 999
} */


.menu {
  border-bottom: none !important;
}

/* Remove border-bottom from Menu.Item if needed */
.menu .ant-menu-item {
  border-bottom: none !important;
}

/* Mobile styles */
@media (max-width: 768px) {
  .header .ant-dropdown-menu {
    background-color: blue !important;
  }

  .header .ant-dropdown-menu-item {
    color: white !important;
  }
  
  .header .ant-menu-horizontal {
    overflow: hidden;
    background-color: white;
  }

  .header .ant-menu-horizontal .ant-menu-item, 
  .header .ant-menu-horizontal .ant-menu-submenu {
    background-color: transparent;
    color: white !important;
  }

  .header .ant-menu-horizontal .ant-menu-item a, 
  .header .ant-menu-horizontal .ant-menu-submenu a {
    color: inherit;
  }

  .header .custom-ellipsis-button {
    color: white;
  }
}