.banner {
    background: transparent;
    color: #fff;
    position: relative;
    height: 682px
}

.hero {
    background-image: url('../../../assets/bg-home.jpg');
    background-size: cover;
    background-position: bottom;
    /* padding: 222px 0 150px; */
    opacity: .85
  }
  
  .bg-overlay {
    background-color: transparent;
    opacity: .85;
    position: absolute;
    height: 100%;
    width: 100%;
    inset: 0;
    background-color: #000;
  }


.banner .banner-anim-elem {
    height: 100%;
    display: flex!important;
    justify-content: center;
    align-items: center;
    text-align: center
}

.banner .banner-anim-elem .text-wrapper .logo-wrapper {
    width: 160px;
    height: 160px;
    margin: auto;
    position: relative
}

.banner .banner-anim-elem .text-wrapper .logo-wrapper img {
    width: 100%;
    height: 100%
}

.banner .banner-anim-elem .text-wrapper .logo-wrapper i {
    background: #fff;
    position: absolute;
    transform-origin: top left
}

.banner .banner-anim-elem .text-wrapper .logo-wrapper .vertical {
    height: 264px;
    width: 1px;
    top: -52px
}

.banner .banner-anim-elem .text-wrapper .logo-wrapper .horizontal {
    width: 264px;
    height: 1px;
    left: -52px
}

.banner .banner-anim-elem .text-wrapper .logo-wrapper .left {
    left: 31px
}

.banner .banner-anim-elem .text-wrapper .logo-wrapper .right {
    left: 55px
}

.banner .banner-anim-elem .text-wrapper .logo-wrapper .top {
    top: 130px
}

.banner .banner-anim-elem .text-wrapper .logo-wrapper .bottom {
    bottom: 4px
}

.banner .banner-anim-elem .text-wrapper .introduce {
    font-size: 16px;
    line-height: 32px;
    font-weight: 400;
    max-width: 557px;
    margin: 80px auto 64px
}

.banner .banner-anim-elem .text-wrapper .button-wrapper .btn-temp {
    background: #fff;
    color: #2f54eb
}

.banner .banner-anim-elem .text-wrapper .button-wrapper .btn-editor {
    border: 1px solid #fff;
    color: #fff;
    margin-left: 24px;
    transition: box-shadow .3s cubic-bezier(.215,.61,.355,1),transform .3s cubic-bezier(.215,.61,.355,1),color .3s cubic-bezier(.215,.61,.355,1),border .3s cubic-bezier(.215,.61,.355,1),background .3s cubic-bezier(.215,.61,.355,1)
}

.banner .banner-anim-elem .text-wrapper .button-wrapper .btn-editor:hover {
    background: #fff;
    color: #2f54eb;
    border-color: transparent
}

.banner .banner-anim-elem .text-wrapper .git-button {
    display: flex;
    justify-content: center;
    margin-top: 24px
}

.banner .banner-anim-elem .bg-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center
}